<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <h1 class="text-h6">Upload</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>Properties</v-card-title>
                    <v-card-text>
                        <v-file-input
                            v-model="selectedFile"
                            accept=".xlsx"
                            label="Choose a file for Properties"
                        ></v-file-input>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            @click="uploadFile('Properties')"
                            :disabled="uploading"
                            class="ml-2 mb-2"
                        >
                            {{ uploading ? "Uploading..." : "Upload File" }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-snackbar
                    v-model="snackbar"
                    :color="snackbarColor"
                    timeout="5000"
                >
                    {{ snackbarText }}
                </v-snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'

export default {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data() {
        return {
            selectedFile: null,
            uploading: false,
            snackbar: false,
            snackbarText: '',
            snackbarColor: '',
        }
    },
    methods: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        handleFileUpload() {
            this.selectedFile = this.$refs.fileInput.files[0]
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        uploadFile(documentType) {
            if (!this.selectedFile) {
                this.showSnackbar('Please select a file to upload.', 'error')
                return
            }

            const formData = new FormData()
            formData.append('file', this.selectedFile)

            this.uploading = true

            axios
                .post(`/api/upload/${documentType}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(() => {
                    this.showSnackbar(`File uploaded successfully for ${documentType}.`, 'success')
                    // Handle any further actions or feedback to the user
                })
                .catch((error) => {
                    console.error(`Error uploading file for ${documentType}:`, error)
                    this.showSnackbar(`Error uploading file for ${documentType}. Please try again.`, 'error')
                })
                .finally(() => {
                    this.uploading = false
                })
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        showSnackbar(text, color) {
            this.snackbarText = text
            this.snackbarColor = color
            this.snackbar = true
        },
    },
}
</script>
